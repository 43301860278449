import Box from '@mui/material/Box'
import Image from 'mui-image'

import Heading from '../Heading'

import aboutOnecultur2 from '../../assets/aboutusOnecultur2.png'
import Paragraph from '../Paragraph'

export default function OurInitiatives () {
  return (
    <Box sx={{
      display: { md: 'flex' },
      justifyContent: { md: 'space-between' },
      paddingTop: { xs: '50px', md: '200px' },
      paddingBottom: { md: '100px' }
    }}
    >
      <Box sx={{ paddingTop: '30px' }}>
        <Heading
          fontWeight='bold'
          color='primary.main'
          fontSize={{ xs: '30px', md: '48px' }}
        >
          The
          <br />
          Initiative
        </Heading>
        <Paragraph
          paddingTop='20px'
          fontSize={{ xs: '12px', md: '16px' }}
          textAlign='left'
          lineHeight={{ xs: '20px', md: '32px' }}
          width={{ xs: '300px', md: '400px' }}
          paddingBottom={{ xs: '20px', md: '0px' }}
        >
          The initiative represents a collaboration between Kunoch and Songhai, both renowned industry leaders united by common objectives. At the core of the OneCultur movement lies a comprehensive approach to addressing the triple challenge of poverty, unemployment, and environmental degradation in Africa. Additionally, the initiative aims to democratize Agribusiness, making it accessible to the average African, while promoting sustainable practices for a better future.
        </Paragraph>
      </Box>
      <Box>
        <Image showLoading src={aboutOnecultur2} width='100%' height='100%' />
      </Box>
    </Box>
  )
}
