import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import './App.css'
import Footer from './components/Footer'
import Loading from './components/Loading'
import Navbar from './components/Navbar'
import GetInTouch from './components/GetInTouch'

import AboutUs from './pages/AboutUs'
import Home from './pages/Home'
import OurFarms from './pages/OurFarms'
import Gallery from './components/Gallery'
import Contact from './components/Contact'
import Unsubscribe from './components/Unsubscribe'
import AdminLogin from './components/admin/AdminLogin'
// import Initiatives from './pages/Initiatives'
import AdminHome from './components/admin/AdminHome'
import NotFound from './components/NotFound'
import ScrollToTop from './utils/ScrollToTop'
import {
  ABOUT_US_ROUTE, HOME_ROUTE,
  OUR_FARMS_ROUTE, GALLERY_ROUTE,
  CONTACT_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_HOME_ROUTE,
  UNSUBSCRIBE_ROUTE
  // INITIATIVES_ROUTE
} from './utils/routes'
import { theme } from './utils/theme'
import '@fontsource/dm-sans'

function App () {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route exact path={ADMIN_HOME_ROUTE} element={<AdminHome />} />
              <Route exact path={ADMIN_LOGIN_ROUTE} element={<AdminLogin />} />
              <Route exact path={UNSUBSCRIBE_ROUTE} element={<Unsubscribe />} />
              <Route exact path={HOME_ROUTE} element={<Home />} />
              <Route exact path={ABOUT_US_ROUTE} element={<AboutUs />} />
              <Route exact path={OUR_FARMS_ROUTE} element={<OurFarms />} />
              <Route exact path={GALLERY_ROUTE} element={<Gallery />} />
              <Route exact path={CONTACT_ROUTE} element={<Contact />} />
              <Route exact path='/admin' element={<Navigate to={ADMIN_HOME_ROUTE} />} />
              {/* <Route exact path={INITIATIVES_ROUTE} element={<Initiatives />} /> */}
              <Route exact path='*' element={<NotFound />} />
            </Routes>
          </ScrollToTop>
          <GetInTouch />
          <Footer />
        </Suspense>
      </ThemeProvider>
    </>
  )
}

export default App
