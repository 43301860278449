import Box from '@mui/material/Box'

export default function Paragraph ({ children, fontSize, textAlign, paddingTop, ...props }) {
  return (
    <Box sx={{
      fontFamily: 'Fira Sans',
      fontSize,
      textAlign,
      paddingTop,
      ...props
    }}
    >
      {children}
    </Box>
  )
}
