import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { Formik } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'

import ButtonComponent from './Button'
import subscriberValidator,
{
  NAME, NAME_LABEL,
  EMAIL, EMAIL_LABEL
} from '../validators/subscribeValidator'
import {
  HOME_ROUTE,
  ABOUT_US_ROUTE,
  OUR_FARMS_ROUTE,
  GALLERY_ROUTE,
  CONTACT_ROUTE
  // INITIATIVES_ROUTE
} from '../utils/routes'

import logo from '../assets/logo.svg'

const initialValues = {
  [NAME]: '',
  [EMAIL]: ''
}

export default function Footer () {
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)

  return (
    <Box sx={{
      display: pathname.split('/').includes('admin') ? 'none' : 'block',
      paddingTop: { xs: '50px', md: '100px' },
      paddingBottom: '50px'
    }}
    >
      <Container maxWidth='lg'>
        <Box sx={{
          display: { md: 'flex' },
          justifyContent: { md: 'space-between' },
          paddingBottom: { md: '50px' }
        }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <img src={logo} alt='logo' width='100%' height='100%' />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
              <Box sx={{ width: '250px', textAlign: { xs: 'center', md: 'left' } }}>
                Get updates and insights on the agribusiness ecosystem from the eyes of our industry experts.
              </Box>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={subscriberValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                  setLoading(true)

                  try {
                    const { data } = await axios.post(
                      `${process.env.REACT_APP_PROD_MODE === 'test'
                        ? process.env.REACT_APP_API_URL_TEST
                        : process.env.REACT_APP_API_URL_LIVE}/subscribe`,
                      { ...values }
                    )

                    // localStorage.setItem('adminOnecultur', JSON.stringify(data.decodeDetails))
                    // localStorage.setItem('adminOneculturToken', data.token)

                    // navigate(ADMIN_HOME_ROUTE)

                    Notify.success(data.msg)
                  } catch (error) {
                    Notify.failure(error.response.data.msg)
                  }
                  setLoading(false)
                  resetForm()
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <Box>
                        <TextField
                          style={{ background: '#e6e6e6' }}
                          sx={{
                            borderRadius: '0px',
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '0px',
                              fontFamily: 'Fira Sans'
                            }
                          }}
                          error={errors[NAME] !== undefined}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={NAME_LABEL}
                          variant='outlined'
                          name={NAME}
                        />
                        <Box sx={{
                          color: 'red',
                          paddingTop: '6px',
                          fontSize: '11px'
                        }}
                        >{errors[NAME]}
                        </Box>
                      </Box>
                      <Box>
                        <TextField
                          style={{ background: '#e6e6e6' }}
                          sx={{
                            borderRadius: '0px',
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '0px',
                              fontFamily: 'Fira Sans'
                            }
                          }}
                          error={errors[EMAIL] !== undefined}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name={EMAIL}
                          placeholder={EMAIL_LABEL}
                          variant='outlined'
                        />
                        <Box sx={{
                          color: 'red',
                          paddingTop: '6px',
                          fontSize: '11px'
                        }}
                        >{errors[EMAIL]}
                        </Box>
                      </Box>
                      {/* <Box
                        sx={{ height: '200px' }}
                      > */}
                      <ButtonComponent
                        type='submit'
                        height='55px'
                        disable={!loading}
                        borderRadius='0px'
                        variant='contained'
                        text={loading ? <CircularProgress /> : 'Subscribe'}
                      />
                      {/* </Box> */}
                    </Stack>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 20 }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: { xs: 'center', md: 'left' }
            }}
            >
              <Box sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: { xs: '18px', md: '18px' },
                paddingTop: { xs: '50px', md: '0px' },
                paddingBottom: '10px'
              }}
              >Website
              </Box>
              <Box
                sx={{ color: 'black !important' }}
                component={Link} to={HOME_ROUTE}
              >Home
              </Box>
              <Box
                sx={{ color: 'black !important' }}
                component={Link} to={ABOUT_US_ROUTE}
              >About Us
              </Box>
              {/* <Box
                sx={{ color: 'black !important' }}
                component={Link} to={INITIATIVES_ROUTE}
              >Initiatives
              </Box> */}
              <Box
                sx={{ color: 'black !important' }}
                component={Link} to={OUR_FARMS_ROUTE}
              >Our Farms
              </Box>
              <Box
                sx={{ color: 'black !important' }}
                component={Link} to={GALLERY_ROUTE}
              >Galleries
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: { xs: 'center', md: 'left' }
            }}
            >
              <Box sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: { xs: '18px', md: '18px' },
                //   paddingTop: '50px',
                paddingBottom: '10px'
              }}
              >Resources
              </Box>
              {/* <Box component={Link} to={CONTACT_ROUTE}>Join</Box> */}
              <Box
                sx={{ color: 'black !important' }}
                component={Link} to={CONTACT_ROUTE}
              >Contact Us
              </Box>
              {/* <Box>Donate</Box> */}
            </Box>
          </Stack>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '50px',
          paddingBottom: { xs: '30px', md: '20px' }
        }}
        >
          © 2022 OneCultur
        </Box>
        <Divider sx={{
          borderColor: '#0C9347',
          borderWidth: '1px'
        }}
        />
      </Container>
    </Box>
  )
}
