import Box from '@mui/material/Box'

export default function Heading ({
  children,
  width,
  textAlign,
  color,
  fontWeight,
  fontSize,
  ...props
}) {
  return (
    <Box
      sx={{
        fontFamily: 'Bodoni Moda',
        width,
        textAlign,
        color,
        fontWeight,
        fontSize,
        ...props

      }}

    >
      {children}
    </Box>
  )
}
