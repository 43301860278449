import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import MenuItem from '@mui/material/MenuItem'
import CancelIcon from '@mui/icons-material/Cancel'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { Formik } from 'formik'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import AdminPage from './AdminPage'
import Button from '../Button'
import AdminImagesViewer from './AdminImagesViewer'
import placeHolder from '../../assets/Image.png'
import InputField from '../InputField'
import Loading from '../Loading'
import uploadImageValidator, {
  UPLOAD_FILE,
  ALBUM,
  ALBUM_LABEL
} from '../../validators/uploadImageValidator'
import { ADMIN_LOGIN_ROUTE } from '../../utils/routes'

const initialValues = {
  [UPLOAD_FILE]: '',
  [ALBUM]: ''
}

const albums = [
  {
    value: 'gallery',
    label: 'Gallery'
  },
  {
    value: 'nazeFarms',
    label: 'Naze Farms'
  },
  {
    value: 'ngorOkpala',
    label: 'Ngor Okpala'
  }
]

export default function AdminHome () {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedAlbum, setSelecetedAlbum] = useState('gallery')
  const [galleries, setGalleries] = useState([])
  const [nazeFarms, setNazeFarms] = useState([])
  const [ngorOkpala, setNgorOkpala] = useState([])
  const [perPageGallery, setPerPageGallery] = useState(8)
  const [hasNextGallery, setHasNextGallery] = useState('')
  const [hasPreviousGallery, setHasPreviousGallery] = useState('')
  const [totalGallery, setTotalGallery] = useState('')
  const [currentPageGallery, setCurrentPageGallery] = useState(1)
  const [perPageNazeFarms, setPerPageNazeFarms] = useState(8)
  const [hasNextNazeFarms, setHasNextNazeFarms] = useState('')
  const [hasPreviousNazeFarms, setHasPreviousNazeFarms] = useState('')
  const [totalNazeFarms, setTotalNazeFarms] = useState('')
  const [currentPageNazeFarms, setCurrentPageNazeFarms] = useState(1)
  const [perPageNgorOkpala, setPerPageNgorOkpala] = useState(8)
  const [hasNextNgorOkpala, setHasNextNgorOkpala] = useState('')
  const [hasPreviousNgorOkpala, setHasPreviousNgorOkpala] = useState('')
  const [totalNgorOkpala, setTotalNgorOkpala] = useState('')
  const [currentPageNgorOkpala, setCurrentPageNgorOkpala] = useState(1)
  const navigate = useNavigate()

  const deleteImage = async (id, images, setState) => {
    const token = localStorage.getItem('adminOneculturToken')

    try {
      const { data } = await axios.delete(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/delete-new-image/${id}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )

      if (data.status === 200) {
        const newImages = images.filter(image => image._id !== id)
        setState([...newImages])
        Notify.success(data.message)
      }
    } catch (error) {
      Notify.failure(error.response.data.message)
    }
    getImages()
  }

  const getImages = useCallback(async () => {
    setLoading(true)
    const token = localStorage.getItem('adminOneculturToken')

    try {
      const getGalleryImages = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/new-get-images/gallery?page=${currentPageGallery}&perpage=${perPageGallery}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )

      setHasNextGallery(getGalleryImages.data.pagination.hasNext)
      setHasPreviousGallery(getGalleryImages.data.pagination.hasPrevious)
      setCurrentPageGallery(getGalleryImages.data.pagination.currentPage)
      setTotalGallery(getGalleryImages.data.pagination.total)
      setGalleries([...getGalleryImages.data.allImages])

      const getGalleryNazeFarms = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/new-get-images/nazeFarms?page=${currentPageNazeFarms}&perpage=${perPageNazeFarms}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )

      setHasNextNazeFarms(getGalleryNazeFarms.data.pagination.hasNext)
      setHasPreviousNazeFarms(getGalleryNazeFarms.data.pagination.hasPrevious)
      setCurrentPageNazeFarms(getGalleryNazeFarms.data.pagination.currentPage)
      setTotalNazeFarms(getGalleryNazeFarms.data.pagination.total)
      setNazeFarms([...getGalleryNazeFarms.data.allImages])

      const getGalleryNgorOkpala = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/new-get-images/ngorOkpala?page=${currentPageNgorOkpala}&perpage=${perPageNgorOkpala}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )

      setHasNextNgorOkpala(getGalleryNgorOkpala.data.pagination.hasNext)
      setHasPreviousNgorOkpala(getGalleryNgorOkpala.data.pagination.hasPrevious)
      setCurrentPageNgorOkpala(getGalleryNgorOkpala.data.pagination.currentPage)
      setTotalNgorOkpala(getGalleryNgorOkpala.data.pagination.total)
      setNgorOkpala([...getGalleryNgorOkpala.data.allImages])
      Notify.success('Images fetched successfully')
    } catch (error) {
      Notify.failure('Server error, please try again.')
    }
    setLoading(false)
  }, [
    currentPageGallery,
    currentPageNgorOkpala,
    currentPageNazeFarms,
    perPageGallery,
    perPageNgorOkpala,
    perPageNazeFarms
  ])

  const handleDialogOpen = () => setOpenDialog(!openDialog)

  const handleChangeAlbum = album => setSelecetedAlbum(album)

  useEffect(() => {
    if (
      localStorage.getItem('adminOnecultur') === undefined ||
      localStorage.getItem('adminOneculturToken') === undefined ||
      localStorage.getItem('adminOnecultur') === null ||
      localStorage.getItem('adminOneculturToken') === null
    ) {
      navigate(ADMIN_LOGIN_ROUTE)
    }
  }, [navigate])

  useEffect(() => {
    getImages()
  }, [
    getImages,
    currentPageNgorOkpala,
    currentPageGallery,
    currentPageNazeFarms
  ])

  return (
    <AdminPage>
      <Container sx={{ backgroundColor: '#FBF7EC' }} maxWidth='lg'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box
              sx={{
                fontWeight: 600,
                fontFamily: 'Fira Sans',
                color: 'primary.main',
                fontSize: '25px'
              }}
            >
              Admin Portal
            </Box>
            <Stack
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                paddingTop: '20px'
              }}
              direction='row'
              spacing={2}
            >
              <Button
                onClick={() => handleChangeAlbum('gallery')}
                variant='contained'
                text='Gallery'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'gallery' ? 'primary.main' : 'transparent'
                }
                color={selectedAlbum === 'gallery' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
              <Button
                onClick={() => handleChangeAlbum('nazeFarms')}
                variant='contained'
                text='Naze Farms'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'nazeFarms' ? 'primary.main' : 'transparent'
                }
                color={selectedAlbum === 'nazeFarms' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
              <Button
                onClick={() => handleChangeAlbum('ngor Okpala')}
                variant='contained'
                text='Ngor Okpala'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'ngor Okpala'
                    ? 'primary.main'
                    : 'transparent'
                }
                color={selectedAlbum === 'ngor Okpala' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
            </Stack>
          </Box>
          <Box>
            <Button
              disabled={loading}
              onClick={handleDialogOpen}
              variant='contained'
              text='Upload New Photo'
              borderRadius='0px'
              backgroundColor='secondary.main'
              width='100%'
              color='white'
            />
          </Box>
        </Box>
        <Box sx={{ paddingBottom: '50px' }}>
          <Stack
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'center',
              paddingTop: '50px'
            }}
            direction='column'
            spacing={2}
            justifyContent='center'
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={() => handleChangeAlbum('gallery')}
                variant='contained'
                text='Gallery'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'gallery' ? 'primary.main' : 'transparent'
                }
                color={selectedAlbum === 'gallery' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={() => handleChangeAlbum('nazeFarms')}
                variant='contained'
                text='Naze Farms'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'nazeFarms' ? 'primary.main' : 'transparent'
                }
                color={selectedAlbum === 'nazeFarms' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={() => handleChangeAlbum('ngor Okpala')}
                variant='contained'
                text='Ngor Okpala'
                borderRadius='0px'
                backgroundColor={
                  selectedAlbum === 'ngor Okpala'
                    ? 'primary.main'
                    : 'transparent'
                }
                color={selectedAlbum === 'ngor Okpala' ? 'white' : 'black'}
                fontFamily='Fira Code'
              />
            </Box>
          </Stack>
        </Box>
        <Divider />

        {loading && (
          <Box>
            <Loading />
          </Box>
        )}
        {selectedAlbum === 'gallery' && !loading && (
          <AdminImagesViewer
            deleteImage={deleteImage}
            perPage={perPageGallery}
            hasNext={hasNextGallery}
            hasPrevious={hasPreviousGallery}
            total={totalGallery}
            currentPage={currentPageGallery}
            setPerPage={setPerPageGallery}
            setHasNext={setHasNextGallery}
            setHasPrevious={setHasPreviousGallery}
            setTotal={setTotalGallery}
            setCurrentPage={setCurrentPageGallery}
            images={galleries}
            setImages={setGalleries}
          />
        )}

        {selectedAlbum === 'nazeFarms' && !loading && (
          <AdminImagesViewer
            deleteImage={deleteImage}
            perPage={perPageNazeFarms}
            hasNext={hasNextNazeFarms}
            hasPrevious={hasPreviousNazeFarms}
            total={totalNazeFarms}
            currentPage={currentPageNazeFarms}
            setPerPage={setPerPageNazeFarms}
            setHasNext={setHasNextNazeFarms}
            setHasPrevious={setHasPreviousNazeFarms}
            setTotal={setTotalNazeFarms}
            setCurrentPage={setCurrentPageNazeFarms}
            setImages={setNazeFarms}
            images={nazeFarms}
          />
        )}
        {selectedAlbum === 'ngor Okpala' && !loading && (
          <AdminImagesViewer
            deleteImage={deleteImage}
            perPage={perPageNgorOkpala}
            hasNext={hasNextNgorOkpala}
            hasPrevious={hasPreviousNgorOkpala}
            total={totalNgorOkpala}
            currentPage={currentPageNgorOkpala}
            setPerPage={setPerPageNgorOkpala}
            setHasNext={setHasNextNgorOkpala}
            setHasPrevious={setHasPreviousNgorOkpala}
            setTotal={setTotalNgorOkpala}
            setCurrentPage={setCurrentPageNgorOkpala}
            setImages={setNgorOkpala}
            images={ngorOkpala}
          />
        )}
      </Container>

      {/* upload file dialog */}
      <Box>
        <Dialog open={openDialog} onClose={handleDialogOpen}>
          <Box
            onClick={handleDialogOpen}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontFamily: 'Fira Code',
              fontWeight: 700,
              paddingBottom: '30px',
              padding: '10px',
              cursor: 'pointer'
            }}
          >
            <CancelIcon sx={{ fontSize: '30px' }} />
          </Box>
          <Box
            sx={{
              padding: '40px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Fira Code',
                fontWeight: 700,
                fontSize: '30px',
                paddingBottom: '30px'
              }}
            >
              Upload Image
            </Box>
            <Box>
              <Formik
                initialValues={initialValues}
                validationSchema={uploadImageValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                  setLoading(true)

                  try {
                    const token = localStorage.getItem('adminOneculturToken')

                    const res = await axios.post(
                      `${
                        process.env.REACT_APP_PROD_MODE === 'test'
                          ? process.env.REACT_APP_API_URL_TEST
                          : process.env.REACT_APP_API_URL_LIVE
                      }/admin/upload`,
                      values,
                      {
                        headers: {
                          'x-auth-token': token,
                          'Content-Type': 'application/json'
                        }
                      }
                    )

                    if (res.data.status === 201) {
                      handleDialogOpen()
                      Notify.success(res.data.message)
                    }
                  } catch (error) {
                    Notify.failure(error.response.data.msg)
                  }
                  setLoading(false)
                  resetForm()
                  getImages()
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values,
                  setFieldError,
                  setFieldTouched
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction='column' spacing={2}>
                      <InputField
                        type='file'
                        accept='image/*'
                        name={UPLOAD_FILE}
                        error={errors[UPLOAD_FILE]}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={e => {
                          e.persist()

                          if (!e.target.files.length) {
                            return setFieldValue(UPLOAD_FILE, '')
                          }

                          setFieldTouched(UPLOAD_FILE, true, false)

                          const file = e.target.files[0]

                          const fileSize = file.size / 1024 / 1024

                          if (fileSize > 2) {
                            e.target.value = ''
                            Notify.success('File size cannot be more than 2MB.')
                            return setFieldError(
                              UPLOAD_FILE,
                              'File size cannot be more than 2MB.'
                            )
                          }
                          const reader = new window.FileReader()

                          reader.readAsDataURL(file)

                          reader.onload = () => {
                            setFieldValue(UPLOAD_FILE, reader.result)
                          }

                          reader.onerror = () => setFieldValue(UPLOAD_FILE, '')
                        }}
                        // label={UPLOAD_FILE_LABEL}
                        variant='outlined'
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          alt=''
                          src={
                            values.image_file !== ''
                              ? values.image_file
                              : placeHolder
                          }
                          width='100%'
                          height='100%'
                        />
                      </Box>
                      <InputField
                        select
                        name={ALBUM}
                        error={errors[ALBUM]}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={ALBUM_LABEL}
                        variant='outlined'
                      >
                        {albums.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                      <Box>
                        <Button
                          disabled={loading}
                          type='submit'
                          variant='contained'
                          text={loading ? <CircularProgress /> : 'Upload Image'}
                          borderRadius='0px'
                          backgroundColor='secondary.main'
                          width='100%'
                          color='white'
                        />
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </AdminPage>
  )
}
