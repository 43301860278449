import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useState, Fragment } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import logo from '../../assets/logo.svg'
import {
  ABOUT_US_ROUTE, HOME_ROUTE,
  OUR_FARMS_ROUTE, GALLERY_ROUTE,
  CONTACT_ROUTE
} from '../../utils/routes'
import ButtonComponent from '../Button'
import styles from './styles/navbar.module.css'

export default function Index () {
  const [anchorElNav, setAnchorElNav] = useState(false)
  const { pathname } = useLocation()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(false)
  }

  return (
    <Box sx={{
      display: pathname.split('/').includes('admin') ? 'none' : 'block'
    }}
    >
      <Box sx={{ backgroundColor: 'primary.main' }}>
        <Container
          maxWidth='lg'
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              fontSize: { xs: '11px', md: '15px' },
              color: 'white'
            }}
            component={Typography}
            variant='h6'
          >
            <Box sx={{ color: 'white !important' }} component='a' href='tel:254-791-777-444' pr='15px'>+234 802 222 7279</Box>
            <Box
              sx={{ color: 'white !important' }}
              component='a'
              href='mailto:contact@onecultur.com'
            >
              contact@onecultur.com
            </Box>
          </Box>
        </Container>
      </Box>
      <AppBar elevation={0} sx={{ backgroundColor: 'white' }} position='static'>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <Box
              component={NavLink}
              to='/'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem'
              }}
            >

              <img src={logo} alt='logo' width='100%' height='100%' />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >

                <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    to={HOME_ROUTE}
                    className={pathname === HOME_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'

                    >
                      Home
                    </Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    className={pathname === ABOUT_US_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                    to={ABOUT_US_ROUTE}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'
                    >About Us
                    </Typography>
                  </NavLink>
                </MenuItem>
                {/* <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    to={INITIATIVES_ROUTE}
                    className={pathname === INITIATIVES_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'
                    > Initiatives
                    </Typography>
                  </NavLink>
                </MenuItem> */}
                <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    to={OUR_FARMS_ROUTE}
                    className={pathname === OUR_FARMS_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'
                    > Our Farms
                    </Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    className={pathname === GALLERY_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                    to={GALLERY_ROUTE}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'
                    >  Gallery
                    </Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                >
                  <NavLink
                    to={CONTACT_ROUTE}
                    className={pathname === CONTACT_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                  >
                    <Typography
                      sx={{ fontFamily: 'Fira Sans' }}
                      textAlign='center'
                    >  Contact
                    </Typography>
                  </NavLink>
                </MenuItem>
              </Menu>
            </Box>
            <Box
              component={NavLink}
              to='/'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                justifyContent: 'center',
                textDecoration: 'none'
              }}
            >
              <img src={logo} alt='logo' width='60%' height='100%' />
            </Box>
            <Box sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              paddingTop: '8px'
            }}
            >
              <NavLink
                to={HOME_ROUTE}
                className={pathname === HOME_ROUTE ? styles.activeStyle : styles.unActiveStyle}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', mr: 3 }}
                >
                  Home
                </Box>
              </NavLink>
              <NavLink
                to={ABOUT_US_ROUTE}
                className={pathname === ABOUT_US_ROUTE ? styles.activeStyle : styles.unActiveStyle}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: 'block',
                    mr: 3
                  }}
                >
                  About Us
                </Box>
              </NavLink>
              {/* <NavLink
                to={INITIATIVES_ROUTE}
                className={pathname === INITIATIVES_ROUTE ? styles.activeStyle : styles.unActiveStyle}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', mr: 3 }}
                >
                  Initiatives
                </Box>
              </NavLink> */}
              <NavLink
                to={OUR_FARMS_ROUTE}
                className={pathname === OUR_FARMS_ROUTE ? styles.activeStyle : styles.unActiveStyle}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', mr: 3 }}
                >
                  Our Farms
                </Box>
              </NavLink>
              <NavLink
                className={pathname === GALLERY_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                to={GALLERY_ROUTE}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', mr: 3 }}
                >
                  Gallery
                </Box>
              </NavLink>
              <NavLink
                className={pathname === CONTACT_ROUTE ? styles.activeStyle : styles.unActiveStyle}
                to={CONTACT_ROUTE}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block', mr: 3 }}
                >
                  Contact
                </Box>
              </NavLink>
            </Box>
            <NavLink to={CONTACT_ROUTE}>
              <Box sx={{ flexGrow: 0, color: 'black' }}>
                <ButtonComponent
                  sx={{
                    fontSize: { xs: '9px', md: '14px' },
                    fontWeight: 500
                  }} variant='contained' text='Contact Us'
                />
              </Box>
            </NavLink>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
