import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Slide from '@mui/material/Slide'

import Nazefarms from '../components/OurFarms/NazeFarms'
import OkpalaFarms from '../components/OurFarms/OkpalaFarms'
import OneCultur from '../components/OurFarms/OneCultur'

export default function OurFarms () {
  return (
    <Slide direction='left' in mountOnEnter unmountOnExit>
      <Box sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}>
        <Container maxWidth='lg'>
          <OneCultur />
          <Nazefarms />
          <OkpalaFarms />
        </Container>
      </Box>
    </Slide>
  )
}
