import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Slide from '@mui/material/Slide'

import Model from '../components/AboutUs/Model'
import OneCultur from '../components/AboutUs/OneCultur'
import OurInitiatives from '../components/AboutUs/OurInitiatives'

export default function AboutUs () {
  return (
    <Slide direction='left' in mountOnEnter unmountOnExit>
      <Box sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}>
        <Container maxWidth='lg'>
          <OneCultur />
          <OurInitiatives />
        </Container>
        <Box sx={{ backgroundColor: '#ECEAE4' }}>
          <Model />
        </Box>
      </Box>
    </Slide>
  )
}
