import Box from '@mui/material/Box'
import LightGallery from 'lightgallery/react'
import axios from 'axios'
import Image from 'mui-image'
import { useEffect, useState, useCallback } from 'react'
// import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
// import NavigateNextIcon from '@mui/icons-material/NavigateNext'
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import Slide from '@mui/material/Slide'

// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

// import plugins if you need
import Heading from '../Heading'
// import arrowLeft from '../../assets/ArrowCircleLeft.svg'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

import Loading from '../Loading'
import './styles/gallery.css'

export default function Gallery () {
  const [galleries, setGalleries] = useState([])
  const [perPageGallery, setPerPageGallery] = useState(9)
  const [hasNextGallery, setHasNextGallery] = useState('')
  const [hasPreviousGallery, setHasPreviousGallery] = useState('')
  const [totalGallery, setTotalGallery] = useState('')
  const [currentPageGallery, setCurrentPageGallery] = useState(1)
  const [loading, setLoading] = useState(false)

  const getGalleries = useCallback(async () => {
    setLoading(true)

    try {
      const getGalleryImages = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/new-get-images/gallery?page=${currentPageGallery}&perpage=${perPageGallery}`
      )

      setHasNextGallery(getGalleryImages.data.pagination.hasNext)
      setHasPreviousGallery(getGalleryImages.data.pagination.hasPrevious)
      setCurrentPageGallery(getGalleryImages.data.pagination.currentPage)
      setTotalGallery(getGalleryImages.data.pagination.total)
      setGalleries([...getGalleryImages.data.allImages])
    } catch (error) {}
    setLoading(false)
  }, [currentPageGallery, perPageGallery])

  useEffect(() => {
    getGalleries()
  }, [currentPageGallery, getGalleries])

  return (
    <Slide direction='left' in mountOnEnter unmountOnExit>
      <Box sx={{ backgroundColor: '#FBF7EC' }}>
        <Container
          sx={{
            paddingTop: '60px',
            minHeight: '100vh',
            paddingBottom: '60px'
          }}
          maxWidth='lg'
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '30px'
            }}
          >
            <Box>
              <Heading
                fontWeight='bold'
                color='primary.main'
                fontSize={{ xs: '30px', md: '50px' }}
              >
                Gallery
              </Heading>
            </Box>
            <Box
              sx={{
                alignSelf: { md: 'center' }
              }}
            >
              <Stack
                direction='row'
                display='flex'
                justifyContent='center'
                spacing={{ xs: 2, md: 5 }}
              >
                <ArrowCircleLeftIcon
                  disabled={!hasPreviousGallery}
                  onClick={() => setCurrentPageGallery(currentPageGallery - 1)}
                  sx={{
                    alignSelf: 'center',
                    color: 'primary.main',
                    fill: !hasPreviousGallery ? 'grey' : 'primary.main',
                    fontSize: '30px',
                    cursor: !hasPreviousGallery ? 'not-allowed' : 'pointer'
                  }}
                />
                <ArrowCircleRightIcon
                  disabled={!hasNextGallery}
                  onClick={() => setCurrentPageGallery(currentPageGallery + 1)}
                  sx={{
                    alignSelf: 'center',
                    color: !hasNextGallery ? 'grey' : 'primary.main',
                    fontSize: '30px',
                    cursor: !hasNextGallery ? 'not-allowed' : 'pointer'
                  }}
                />
              </Stack>
            </Box>
          </Box>

          {loading && (
            <Box sx={{ minHeight: '100vh' }}>
              <Loading />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '100vh'
            }}
          >
            {!loading && galleries.length > 0 && (
              <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                {galleries.map(({ image, text, title }, index) => (
                  <a
                    key={index}
                    style={{
                      paddingBottom: '20px',
                      textDecoration: 'none !important'
                    }}
                    href={image}
                  >
                    <Image width='100%' alt={text} src={image} />
                    <Box
                      sx={{
                        color: 'black'
                      }}
                    >
                      {title}
                    </Box>
                    {/* <div style={{
                    color: 'black',
                    paddingTop: '10px'
                  }}
                  >{text}
                  </div> */}
                  </a>
                ))}
              </LightGallery>
            )}
          </Box>
          <Box
            sx={{
              paddingTop: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignSelf: { md: 'center' }
            }}
          >
            <Stack
              direction='row'
              display='flex'
              justifyContent='center'
              spacing={{ xs: 2, md: 5 }}
            >
              <ArrowCircleLeftIcon
                disabled={!hasPreviousGallery}
                onClick={() => setCurrentPageGallery(currentPageGallery - 1)}
                sx={{
                  alignSelf: 'center',
                  color: 'primary.main',
                  fill: !hasPreviousGallery ? 'grey' : 'primary.main',
                  fontSize: '30px',
                  cursor: !hasPreviousGallery ? 'not-allowed' : 'pointer'
                }}
              />
              <ArrowCircleRightIcon
                disabled={!hasNextGallery}
                onClick={() => setCurrentPageGallery(currentPageGallery + 1)}
                sx={{
                  alignSelf: 'center',
                  color: !hasNextGallery ? 'grey' : 'primary.main',
                  fontSize: '30px',
                  cursor: !hasNextGallery ? 'not-allowed' : 'pointer'
                }}
              />
            </Stack>
          </Box>
        </Container>
      </Box>
    </Slide>
  )
}
