import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Image from 'mui-image'
import Slide from '@mui/material/Slide'
import { useNavigate } from 'react-router-dom'

import Heading from './Heading'
import notFound from '../assets/notfound.svg'
import Paragraph from './Paragraph'
import ButtonComponent from './Button'
import { HOME_ROUTE } from '../utils/routes'

export default function NotFound () {
  const navigate = useNavigate()

  return (
    <Slide direction='left' in mountOnEnter unmountOnExit>
      <Box sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}>
        <Container maxWidth='lg'>
          <Box sx={{ paddingTop: { xs: '60px', md: '200px' }, alignSelf: 'center' }}>
            <Box sx={{ display: { md: 'flex' }, flexDirection: { md: 'row-reverse' } }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Image src={notFound} />
              </Box>
              <Box sx={{ paddingRight: { md: '70px' } }}>
                <Heading
                  paddingTop={{ xs: '40px' }}
                  color='primary.main'
                  fontWeight='bold'
                  fontSize={{ xs: '40px', md: '70px' }}
                >
                  Page not found
                </Heading>
                <Paragraph
                  textAlign={{ xs: 'center', md: 'left' }}
                  paddingTop={{ xs: '20px', md: '40px' }}
                >
                  The page you are looking for does not exist.
                </Paragraph>
                <Box sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  paddingTop: { xs: '20px', md: '60px' }
                }}
                >
                  <ButtonComponent
                    onClick={() => navigate(HOME_ROUTE)}
                    sx={{ backgroundColor: 'primary.main' }}
                    variant='contained'
                    text='Back to Homepage'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Slide>
  )
}
