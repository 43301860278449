import Box from '@mui/material/Box'
import Image from 'mui-image'

import Heading from '../Heading'

// import aboutOnecultur1 from '../../assets/aboutusOnecultur1.svg'
import oneculturAbout from '../../assets/oneculturAbout.png'
import Paragraph from '../Paragraph'

export default function OneCultur () {
  return (
    <Box sx={{
      display: { md: 'flex' },
      justifyContent: { md: 'space-between' },
      paddingTop: '60px'
    }}
    >
      <Box>
        <Image showLoading src={oneculturAbout} width='80%' height='100%' />
      </Box>
      <Box sx={{ paddingTop: '30px' }}>
        <Heading
          fontWeight='bold'
          color='primary.main'
          fontSize={{ xs: '30px', md: '48px' }}
        >
          About
          <br />
          OneCultur
        </Heading>
        <Paragraph
          paddingTop='20px'
          fontSize={{ xs: '12px', md: '16px' }}
          textAlign='left'
          lineHeight={{ xs: '20px', md: '32px' }}
          width={{ xs: '300px', md: '400px' }}
        >
          OneCultur presents an innovative approach to Agriculture, prioritizing environmental sustainability. Our focus is on achieving zero waste, zero downtime, and implementing a complete end-to-end value chain production system. Our ultimate goal is to foster employment opportunities, drive sustainable economic growth, and empower Nigerian youth by showcasing the allure of Agribusiness in Nigeria and beyond, making a positive impact across Africa.
        </Paragraph>
      </Box>
    </Box>
  )
}
