import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ADMIN_LOGIN_ROUTE, ADMIN_HOME_ROUTE } from '../../utils/routes'

import Logo from '../../assets/logo.svg'

export default function AdminPage ({ children }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'white',
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <Container
          maxWidth='lg'
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => navigate(ADMIN_HOME_ROUTE)}
              sx={{
                width: { xs: '50%', md: '15%' },
                cursor: 'pointer'
              }}
            >
              <img alt='' src={Logo} width='100%' height='100%' />
            </Box>
            <Stack
              sx={{ display: pathname === '/admin/login' ? 'none' : 'flex' }}
              spacing={2}
              direction='row'
            >
              <Box
                sx={{
                  alignSelf: 'center',
                  fontFamily: 'Fira code'
                }}
              >
                Admin
              </Box>
              <Button
                id='demo-positioned-button'
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  color: 'white',
                  backgroundColor: 'primary.main'
                }}
              >
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: 'Fira Sans'
                  }}
                  onClick={() => {
                    handleClose()
                    localStorage.removeItem('adminOnecultur')
                    localStorage.removeItem('adminOneculturToken')
                    navigate(ADMIN_LOGIN_ROUTE)
                  }}
                >Logout
                </MenuItem>
              </Menu>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#FBF7EC',
        minHeight: '100vh',
        paddingTop: '50px'
      }}
      >
        {children}
      </Box>
    </Box>
  )
}
