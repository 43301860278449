import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Image from 'mui-image'
import { Link } from 'react-router-dom'

import ourFarmImage from '../../assets/ourFarmImage.png'
import Heading from '../Heading'
import Paragraph from '../Paragraph'
import styles from './styles/home.module.css'
import { OUR_FARMS_ROUTE } from '../../utils/routes'

export default function OurFarms () {
  return (
    <Box
      sx={{
        position: { xs: 'none', md: 'relative' },
        paddingTop: '100px',
        width: '100%'
      }}
    >
      <Image showLoading src={ourFarmImage} alt='our farm' className={styles.imgStyle} />
      <Box sx={{
        position: { xs: 'none', md: 'absolute' },
        top: '60%',
        maxWidth: 'md',
        right: '3%',
        paddingTop: { xs: '30px', md: '0px' }
      }}
      >
        <Container
          maxWidth='lg'
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 540,
                height: { xs: 550, md: 500 },
                padding: { xs: '25px', md: '50px' }
              }
            }}
          >
            <Paper square elevation={0}><Heading fontWeight='bold' fontSize='40px' color='secondary.main'>Our Farms</Heading>
              <Paragraph fontSize='16px' paddingTop='30px'>
                The initiative represents a collaboration between Kunoch and Songhai, both renowned industry leaders united by common objectives. At the core of the OneCultur movement lies a comprehensive approach to addressing the triple challenge of poverty, unemployment, and environmental degradation in Africa. Additionally, the initiative aims to democratize Agribusiness, making it accessible to the average African, while promoting sustainable practices for a better future.
              </Paragraph>
              <Link to={OUR_FARMS_ROUTE}>
                <Paragraph
                  color='primary.main'
                  fontSize='16px'
                  paddingTop='30px'
                >
                  View our farms
                </Paragraph>
              </Link>
            </Paper>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
