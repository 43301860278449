import * as Yup from 'yup'

export const UPLOAD_FILE = 'image_file'
export const UPLOAD_FILE_LABEL = 'Choose Image'

export const ALBUM = 'album'
export const ALBUM_LABEL = 'Album'

export default Yup.object({
  [UPLOAD_FILE]: Yup.string()
    .label(UPLOAD_FILE_LABEL)
    .required(),
  [ALBUM]: Yup.string()
    .label(ALBUM_LABEL)
    .required()
})
