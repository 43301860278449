import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

export default function InputField ({ name, error, type, onBlur, onChange, email, label, accept, ...props }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: { xs: '100%' }
    }}
    >
      <TextField
        accept={accept}
        type={type}
        style={{ background: 'white' }}
        name={name}
        error={error !== undefined}
        fullWidth
        onBlur={onBlur}
        onChange={onChange}
        placeholder={email}
        label={label}
        variant='outlined'
        {...props}
      />
      <Box sx={{
        color: 'red',
        paddingTop: '6px',
        fontSize: '11px'
      }}
      >{error}
      </Box>
    </Box>
  )
}
