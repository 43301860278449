import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useNavigate } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'

import bashLogo from '../../assets/bash.svg'
import innovateLogo from '../../assets/InnovateOne.svg'
import kunochLogo from '../../assets/kunoch.svg'
import nazeFarms from '../../assets/nazefarms.svg'
import ngorOkpalaFarms from '../../assets/ngorOkpalaFarms.svg'
import protea from '../../assets/proteaLogo.png'
import songhai from '../../assets/songhai.svg'
import proteinMaster from '../../assets/proteinMaster.png'
import { ABOUT_US_ROUTE } from '../../utils/routes'
import ButtonComponent from '../Button'
import Heading from '../Heading'
import Paragraph from '../Paragraph'
import heroImage from '../../assets/heroImage.png'

const logoImages = [{ img: kunochLogo }, { img: songhai }, { img: nazeFarms }, { img: proteinMaster, width: '7%' }, { img: protea, width: '10%' }]

export default function HeadingCarousel () {
  const navigate = useNavigate()

  return (
    <>
      <Box sx={{
        paddingTop: '60px',
        display: 'flex',
        justifyContent: 'center'
      }}
      >
        <Stack>
          <Heading
            width={{ xs: '100%', md: '100%' }}
            textAlign='center'
            color='#0C9347'
            fontWeight='bold'
            fontSize={{ xs: '18px', md: '40px' }}
          >
            {/* A Sustainable Agro-business <br />Initaitive for the future! */}
            {/* OneCultur will introduce a new way of engaging <br /> in the business of Agriculture <br />with emphasis on environmental sustainability. */}
            OneCultur Will Introduce a New Way of Engaging  <br /> in the Business of Agriculture <br /> with Emphasis on Environmental Sustainability.
          </Heading>
          {/* <Paragraph
            fontSize='16px'
            textAlign='center'
            paddingTop='10px'
          >
            OneCultur will introduce a new way of engaging in the business of Agriculture
            <br />
            with emphasis on environmental sustainability.
          </Paragraph> */}
          <Box sx={{ textAlign: 'center', paddingTop: '40px' }}>
            <ButtonComponent onClick={() => navigate(ABOUT_US_ROUTE)} variant='contained' text='About Us' />
          </Box>
        </Stack>
      </Box>
      <Container
        maxWidth='lg'
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: { xs: '30px' }
        }}
        >
          <Box>
            <Image showLoading alt='' src={heroImage} width='100%' height='100%' />
          </Box>
        </Box>
        {/* <Box sx={{ paddingTop: { xs: '100px', md: '150px' } }}>
          <Heading fontSize={{ xs: '40px', md: '50px' }} fontWeight='bold' textAlign='center' color='secondary.subMain'>
            Our Partners
          </Heading>
          <Stack
            alignItems='center'
            justifyContent='center'
            spacing={{ md: 4 }}
            direction={{ xs: 'column', md: 'row' }}
          >
            {logoImages.map(({ img, width }, index) => (
              <Box
                sx={{
                  width
                }}
                key={index}
              >
                <Image alt='' src={img} width='100%' height='100%' />
              </Box>
            ))}
          </Stack>
        </Box> */}
      </Container>
    </>
  )
}
