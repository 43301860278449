import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Swiper, SwiperSlide } from 'swiper/react'
// Default theme
import '@splidejs/react-splide/css'

import tractorImage from '../../assets/tractorImage.png'
import Heading from '../Heading'
import Paragraph from '../Paragraph'
// import ArrowCircleLeft from '../../assets/ArrowCircleLeft.svg'
// import ArrowCircleRight from '../../assets/ArrowCircleRight.svg'
import './styles/model.css'

const sliderCopies = [{
  id: 1,
  backgroundColor: 'black',
  title: 'Integrated Production System',
  paragraph: 'The Onecultur Integrated production system aims to achieve sustainability in agriculture through the optimal use of natural resources, agricultural inputs and better waste management. The interrelated, inter-dependent-interlocking nature of the Integrated Production System involves the utilization of primary & secondary produce of one system, as the basic input of the other system, thus making them mutually integrated as one whole unit. Maximization of yield of all component enterprises to provide steady and stable income at higher levels.'
},
{
  id: 2,
  backgroundColor: '#EA088B',
  title: 'A Mindset Reorientation/training Component',
  paragraph: 'New and emerging world view, concepts and principles from modern science are articulated, taught and harnessed in searching for solutions to problems (like youth unemployment, productivity, environmental degradation, economic, social etc.). Access to knowledge in the emerging sustainable or Green Agro-business sector and IT enabled Economy is the basic orientation of this intervention.'
},
{
  id: 3,
  backgroundColor: '#0C9347',
  title: 'An Incubation/training/human Resource Development Center ',
  paragraph: 'An effective incubation space where new competencies are developed. By participating in design, development and production activities, the trainees, facilitated by mentors will ideally develop the technical, organizational and managerial skills necessary to become successful entrepreneurs within their respective agricultural value chains.'
},
{
  id: 4,
  backgroundColor: '#F69220',
  title: 'A service center to create an enabling environment for success ',
  paragraph: 'We have a clear mandate to train, incubate and leverage the seeding of our graduates and creation of enterprises. Graduates from our training programs are leveraged within services such as marketing, input procurement, hospitality services, networking, financial/loan and advisory services and advocacy programs. Our goal is to give our graduates the best chance of success by creating synergy across the Onecultur network. '
},
{
  id: 5,
  backgroundColor: '#EA088B',
  title: 'An Agro-business park ',
  paragraph: 'New technologies, organization patterns and forms aligned with the new worldview are developed and contextualized in collaboration with research institutions and universities as tools for successful transformation of the agricultural sector.'
}]

export default function Model () {
  return (
    <Container
      sx={{ paddingBottom: { md: '50px' } }}
    >
      <Box sx={{
        paddingTop: { xs: '30px', md: '60px' },
        paddingBottom: { md: '50px' }
      }}
      >
        <Heading
          color='secondary.subMain'
          textAlign='center'
          fontWeight='bold'
          fontSize={{ xs: '30px', md: '48xp' }}
        >
          The OneCultur Model
        </Heading>
      </Box>
      <Swiper navigation modules={[Navigation, Autoplay]} className='mySwiper'>
        {sliderCopies.map(({ id, backgroundColor, title, paragraph }) => (
          <SwiperSlide key={id}>
            <Stack
              spacing={{ xs: 5 }}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              <Box sx={{
                paddingTop: '20px',
                paddingRight: { md: '60px' }
              }}
              >
                <img alt='' width='100%' height='100%' src={tractorImage} />
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                <Box sx={{ paddingTop: { xs: '30px', md: '0px' } }}>
                  <Box sx={{ borderTop: '10px solid', width: '30%', color: 'secondary.main' }} />
                  <Stack
                    sx={{
                      paddingTop: '40px'
                    }} direction='row' spacing={{ xs: 5 }}
                  >
                    <Heading
                      borderRadius='50%'
                      width='33px'
                      height='33px'
                      backgroundColor={backgroundColor}
                      color='white'
                      position='relative'
                    >
                      <Box sx={{
                        position: 'absolute',
                        top: '5%',
                        left: '35%',
                        fontWeight: 'bold',
                        fontSize: { xs: '20px', md: '20px' }
                      }}
                      >{id}
                      </Box>
                    </Heading>
                    <Heading
                      fontWeight='bold'
                      fontSize={{ xs: '12px', md: '22px' }}
                      width={{ md: '500px' }}
                    >
                      {title}
                    </Heading>
                  </Stack>
                  <Paragraph
                    paddingTop='20px'
                    paddingBottom='40px'
                    fontSize={{ xs: '12px', md: '16px' }}
                    textAlign='left'
                    lineHeight={{ xs: '20px', md: '32px' }}
                    width={{ xs: '300px', md: '500px' }}
                  >
                    {paragraph}
                  </Paragraph>
                </Box>
              </Box>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}
