import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { Formik } from 'formik'
import { useState } from 'react'
import { Notify } from 'notiflix/build/notiflix-notify-aio'

import unsubscribeValidator, {
  EMAIL,
  EMAIL_LABEL
} from '../validators/unsubscribeValidator'
import ButtonComponent from './Button'

const initialValues = {
  [EMAIL]: ''
}

export default function Unsubscribe () {
  const [loading, setLoading] = useState(false)

  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth='lg'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                paddingTop: '200px',
                textAlign: 'center',
                width: { md: '350px' }
              }}
            >
              Please enter your email below to unsubscribe from the mailing list
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '40px'
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={unsubscribeValidator}
              onSubmit={async (values, { isSubmitting, resetForm }) => {
                setLoading(true)

                try {
                  const { data } = await axios.post(
                      `${process.env.REACT_APP_PROD_MODE === 'test'
                        ? process.env.REACT_APP_API_URL_TEST
                        : process.env.REACT_APP_API_URL_LIVE}/subscribe/unsubscribe`,
                      { ...values }
                  )

                  Notify.success(data.msg)
                } catch (error) {
                  Notify.failure(error.response.data.msg)
                }
                setLoading(false)
                resetForm()
              }}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <Box>
                      <TextField
                        style={{ background: '#e6e6e6' }}
                        sx={{
                          borderRadius: '0px',
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '0px',
                            fontFamily: 'Fira Sans'
                          }
                        }}
                        error={errors[EMAIL] !== undefined}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={EMAIL}
                        placeholder={EMAIL_LABEL}
                        variant='outlined'
                      />
                      <Box sx={{
                        color: 'red',
                        paddingTop: '6px',
                        fontSize: '11px'
                      }}
                      >{errors[EMAIL]}
                      </Box>
                    </Box>
                    {/* <Box
                        sx={{ height: '200px' }}
                      > */}
                    <ButtonComponent
                      type='submit'
                      height='55px'
                      disable={!loading}
                      borderRadius='0px'
                      variant='contained'
                      text={loading ? <CircularProgress /> : 'Unsubscribe'}
                    />
                    {/* </Box> */}
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
