import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import { useNavigate, useLocation } from 'react-router-dom'

import ButtonComponent from './Button'
import Heading from './Heading'
import Paragraph from './Paragraph'
import { CONTACT_ROUTE } from '../utils/routes'

export default function GetInTouch () {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Box
      sx={{
        display: pathname.split('/').includes('admin') ? 'none' : 'block',
        backgroundColor: '#FBF7EC',
        // minHeight: '20vh',
        paddingTop: '100px',
        paddingBottom: '100px'
      }}
    >
      <Container maxWidth='lg'>
        <Paper
          square
          sx={{
            backgroundColor: 'primary.main',
            padding: '50px',
            display: 'flex',
            justifyContent: 'center',
            color: 'white'
          }}
          elevation={0}
        >
          <Box sx={{
            display: ' flex',
            flexDirection: 'column',
            paddingTop: '50px',
            paddingBottom: '50px'
          }}
          >
            <Heading display='flex' justifyContent='center' fontSize={{ xs: '20px', md: '48px' }} fontWeight='bold'>
              Get in Touch
            </Heading>
            <Paragraph paddingTop={{ xs: '20px' }} display='flex' justifyContent='center' textAlign='center' fontSize={{ xs: '12px', md: '16px' }}>
              Interested in one of our OneCultur initiatives or want to learn <br /> more about the movement?
            </Paragraph>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: { xs: '20px' } }}>
              <ButtonComponent onClick={() => navigate(CONTACT_ROUTE)} variant='contained' text='Contact us' />
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}
