import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Image from 'mui-image'

import Heading from '../Heading'
import kunochLogo from '../../assets/kunoch.svg'
import nazeFarms from '../../assets/nazefarms.svg'
import protea from '../../assets/proteaLogo.png'
import songhai from '../../assets/songhai.svg'
import proteinMaster from '../../assets/proteinMaster.png'

const logoImages = [{ img: kunochLogo }, { img: songhai }, { img: nazeFarms }, { img: proteinMaster, width: '7%' }, { img: protea, width: '10%' }]

export default function OurPartners () {
  return (
    <Box sx={{ paddingTop: { xs: '100px', md: '350px' } }}>
      <Heading fontSize={{ xs: '40px', md: '50px' }} fontWeight='bold' textAlign='center' color='secondary.subMain'>
        Our Partners
      </Heading>
      <Stack
        alignItems='center'
        justifyContent='center'
        spacing={{ md: 4 }}
        direction={{ xs: 'column', md: 'row' }}
      >
        {logoImages.map(({ img, width }, index) => (
          <Box
            sx={{
              width
            }}
            key={index}
          >
            <Image alt='' src={img} width='100%' height='100%' />
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
