import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import { Formik } from 'formik'
import { useState } from 'react'
import { Notify } from 'notiflix/build/notiflix-notify-aio'

import Heading from './Heading'
import Paragraph from './Paragraph'
import homeIcon from '../assets/homeIcon.svg'
import phoneIcon from '../assets/phoneIcon.svg'
import mailIcon from '../assets/mailIcon.svg'
import ButtonComponent from '../components/Button'
import contactFormValidator, {
  NAME,
  NAME_LABEL,
  EMAIL,
  EMAIL_LABEL,
  MESSAGE,
  MESSAGE_LABEL,
  SUBJECT,
  SUBJECT_LABEL
} from '../validators/contactFormValidator'

const initialValues = {
  [NAME]: '',
  [EMAIL]: '',
  [MESSAGE]: '',
  [SUBJECT]: ''
}

export default function Contact () {
  const [loading, setLoading] = useState(false)

  return (
    <Box sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}>
      <Container maxWidth='lg'>
        <Box sx={{ paddingTop: '60px' }}>
          <Box />
          <Heading
            fontWeight='bold'
            display='flex'
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            fontSize={{ xs: '30px', md: '48px' }}
            color='primary.main'
          >
            Contact Us
          </Heading>
          <Paragraph
            textAlign={{ xs: 'center', md: 'left' }}
          >
            Kindly fill the form and we will respond within 24hours.
          </Paragraph>
          <Box sx={{ display: { md: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' } }}>
            <Box>
              <Box sx={{ paddingTop: '30px', paddingBottom: '20px' }}>
                <Stack
                  spacing={2}
                  direction='row'
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Box>
                    <img src={homeIcon} alt='homeIcon' width='100%' height='100%' />
                  </Box>
                  <Paragraph
                    fontSize={{ xs: '19px', md: '24px' }}
                    alignSelf='center'
                    fontWeight='bold'
                    color='primary.main'
                  >Address
                  </Paragraph>
                </Stack>
                <a
                  style={{ color: 'black' }}
                  target='_blank'
                  href='https://goo.gl/maps/9iaT3cMqB7TF1afi7'
                  rel='noreferrer'
                >
                  <Paragraph
                    textAlign={{ xs: 'center', md: 'left' }}
                    fontSize={{ xs: '12px', md: '16px' }}
                    paddingTop='10px'
                  >
                    14A Oba Elegushi Road, Ikoyi, Lagos.
                  </Paragraph>
                </a>
              </Box>
              <Box sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Stack
                  spacing={2}
                  direction='row'
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Box>
                    <img alt='mailIcon' src={mailIcon} width='100%' height='100%' />
                  </Box>
                  <Paragraph
                    fontSize={{ xs: '19px', md: '24px' }}
                    alignSelf='center'
                    fontWeight='bold'
                    color='primary.main'
                  >Email
                  </Paragraph>
                </Stack>
                <a
                  style={{ color: 'black' }}
                  href='mailto:kelechi@kunoch.com'
                >
                  <Paragraph
                    textAlign={{ xs: 'center', md: 'left' }}
                    fontSize={{ xs: '12px', md: '16px' }}
                    paddingTop='10px'
                  >
                    kelechi@kunoch.com
                  </Paragraph>
                </a>
                <a
                  style={{ color: 'black' }}
                  href='mailto:tolu@kunoch.com'
                >
                  <Paragraph
                    textAlign={{ xs: 'center', md: 'left' }}
                    fontSize={{ xs: '12px', md: '16px' }}
                    paddingTop='10px'
                  >
                    tolu@kunoch.com
                  </Paragraph>
                </a>
              </Box>
              <Box sx={{ paddingTop: '20px', paddingBottom: '30px' }}>
                <Stack
                  spacing={2}
                  direction='row'
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Box>
                    <img alt='phoneIcon' src={phoneIcon} width='100%' height='100%' />
                  </Box>
                  <Paragraph
                    fontSize={{ xs: '19px', md: '24px' }}
                    alignSelf='center'
                    fontWeight='bold'
                    color='primary.main'
                  >Phone
                  </Paragraph>
                </Stack>
                <a
                  style={{ color: 'black' }}
                  href='tel:234 802 222 7279'
                >
                  <Paragraph
                    textAlign={{ xs: 'center', md: 'left' }}
                    fontSize={{ xs: '12px', md: '16px' }}
                    paddingTop='10px'
                  >
                    +234 802 222 7279
                  </Paragraph>
                </a>
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={contactFormValidator}
              onSubmit={async (values, { isSubmitting, resetForm }) => {
                setLoading(true)

                try {
                  const { data } = await axios.post(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/contact`,
                  { ...values }
                  )

                  Notify.success(data.message)
                } catch (error) {
                  Notify.failure(error.response.data.msg)
                }
                setLoading(false)
                resetForm()
              }}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Stack
                    spacing={{ xs: 3, md: 5 }}
                    direction={{ xs: 'column', md: 'row' }}
                  >
                    <Box sx={{
                      paddingTop: { md: '30px' },
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' }
                    }}
                    >
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%' }
                      }}
                      >
                        <TextField
                          style={{ background: 'white' }}
                          name={NAME}
                          fullWidth
                          error={errors[NAME]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Name'
                          label={NAME_LABEL}
                          variant='outlined'
                        />
                        <Box sx={{
                          color: 'red',
                          paddingTop: '6px',
                          fontSize: '11px'
                        }}
                        >{errors[NAME]}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        paddingTop: { xs: '10px', md: '30px' }
                      }}
                    >
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%' }
                      }}
                      >
                        <TextField
                          style={{ background: 'white' }}
                          name={EMAIL}
                          error={errors[EMAIL] !== undefined}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='Email'
                          label={EMAIL_LABEL}
                          variant='outlined'
                        />
                        <Box sx={{
                          color: 'red',
                          paddingTop: '6px',
                          fontSize: '11px'
                        }}
                        >{errors[EMAIL]}
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      paddingTop: { xs: '20px', md: '30px' }
                    }}
                  >
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%'
                    }}
                    >
                      <TextField
                        style={{ background: 'white' }}
                        name={SUBJECT}
                        error={errors[SUBJECT] !== undefined}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder='SUBJECT'
                        label={SUBJECT_LABEL}
                        variant='outlined'
                      />
                      <Box sx={{
                        color: 'red',
                        paddingTop: '6px',
                        fontSize: '11px'
                      }}
                      >{errors[SUBJECT]}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    paddingTop: '20px',
                    width: '100%'
                  }}
                  >
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%'
                    }}
                    >
                      <TextField
                        style={{
                          background: 'white'
                        }}
                        name={MESSAGE}
                        error={errors[MESSAGE] !== undefined}
                        fullWidth
                        minRows={10}
                        multiline
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder='Write your message'
                        label={MESSAGE_LABEL}
                        variant='outlined'
                      />
                      <Box sx={{
                        color: 'red',
                        paddingTop: '6px',
                        fontSize: '11px'
                      }}
                      >{errors[MESSAGE]}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: '20px',
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' }
                    }}
                  >
                    <ButtonComponent
                      disabled={loading}
                      type='submit'
                      sx={{ backgroundColor: 'primary.main' }}
                      variant='contained'
                      text={loading ? <CircularProgress /> : 'Send Message'}
                    />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
