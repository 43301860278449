import { Box } from '@mui/material'
import Slide from '@mui/material/Slide'

import Galleries from '../components/Home/Galleries'
import HeadingCarousel from '../components/Home/HeadingCarousel'
// import Initiatives from '../components/Home/Initiatives'
import OurFarms from '../components/Home/OurFarms'
import OurPartners from '../components/Home/OurPartners'
// import OurFeeds from '../components/Home/OurFeeds'

export default function Home () {
  return (
    <Slide direction='right' in mountOnEnter unmountOnExit>
      <Box
        sx={{ backgroundColor: '#FBF7EC', minHeight: '100vh' }}
      >
        <HeadingCarousel />
        <OurFarms />
        {/* <Initiatives /> */}
        <Galleries />
        <OurPartners />
        {/* <OurFeeds /> */}
      </Box>
    </Slide>
  )
}
