import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import axios from 'axios'
import Image from 'mui-image'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
// import { Autoplay } from 'swiper'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import 'swiper/css'
import 'swiper/css/pagination'
// import { Swiper, SwiperSlide } from 'swiper/react'

import ButtonComponent from '../Button'
import Heading from '../Heading'
import ArrowCircleLeft from '../../assets/ArrowCircleLeft.svg'
import ArrowCircleRight from '../../assets/ArrowCircleRight.svg'
import { GALLERY_ROUTE } from '../../utils/routes'
// import './styles/galleries.css'

export default function Galleries () {
  const [galleries, setGalleries] = useState([])
  const [hoverImage, setHoverImage] = useState({})

  const getGalleries = async () => {
    try {
      const getGalleryImages = await axios.get(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/admin/new-get-images/gallery`
      )

      setGalleries([...getGalleryImages.data.allImages])
    } catch (error) {}
  }

  useEffect(() => {
    getGalleries()
  }, [])

  console.log('this isghiste', galleries)

  return (
    <Box
      sx={{
        display: galleries.length < 1 ? 'none' : 'flex',
        paddingTop: { xs: '100px', md: '400px' }
      }}
    >
      <Container maxWidth='lg'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '30px'
          }}
        >
          <Box>
            <Heading
              fontWeight='bold'
              color='primary.main'
              fontSize={{ xs: '30px', md: '50px' }}
            >
              Gallery
            </Heading>
          </Box>
          <Box
            component={Link}
            to={GALLERY_ROUTE}
            sx={{ alignSelf: { md: 'center' } }}
          >
            <ButtonComponent
              sx={{
                backgroundColor: 'primary.main',
                borderRadius: '0px'
              }}
              variant='contained'
              endIcon={<ArrowForwardIcon />}
              text='View all'
            />
          </Box>
        </Box>
        {galleries.length > 0 && (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 2,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleries.length > 0 &&
                  galleries.map(({ image, title }, index) => (
                    <SplideSlide
                      style={{
                        width:
                          hoverImage.status && hoverImage.index === index
                            ? '30%'
                            : '20%',
                        transition: 'width 0.3s ease-in-out'
                      }}
                      key={index}
                      onMouseLeave={() =>
                        setHoverImage({ status: false, index })
                      }
                      onMouseEnter={() =>
                        setHoverImage({ status: true, index })
                      }
                    >
                      <Box>
                        <Image src={image} alt='' width='100%' height='100%' />
                      </Box>
                      <Box
                        sx={{
                          color: 'black'
                        }}
                      >
                        {title}
                      </Box>
                    </SplideSlide>
                  ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        )}
        {galleries.length > 0 && (
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Splide
              hasTrack={false}
              options={{
                autoplay: true,
                type: 'loop',
                perPage: 5,
                pagination: false,
                gap: '1rem'
              }}
            >
              <SplideTrack>
                {galleries.length > 0 &&
                  galleries.map(({ image, title }, index) => (
                    <SplideSlide
                      style={{
                        width:
                          hoverImage.status && hoverImage.index === index
                            ? '30%'
                            : '20%',
                        transition: 'width 0.3s ease-in-out'
                      }}
                      key={index}
                      onMouseLeave={() =>
                        setHoverImage({ status: false, index })
                      }
                      onMouseEnter={() =>
                        setHoverImage({ status: true, index })
                      }
                    >
                      <Box>
                        <Image src={image} alt='' width='100%' height='100%' />
                      </Box>
                      <Box
                        sx={{
                          color: 'black'
                        }}
                      >
                        {title}
                      </Box>
                    </SplideSlide>
                  ))}
              </SplideTrack>
              <div className='splide__arrows'>
                <button className='splide__arrow splide__arrow--prev'>
                  <img
                    alt=''
                    src={ArrowCircleLeft}
                    width='120%'
                    height='100%'
                  />
                </button>
                <button className='splide__arrow splide__arrow--next'>
                  <img
                    alt=''
                    src={ArrowCircleRight}
                    width='120%'
                    height='100%'
                  />
                </button>
              </div>
            </Splide>
          </Box>
        )}
      </Container>
    </Box>
  )
}
