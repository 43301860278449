import * as Yup from 'yup'

export const NAME = 'name'
export const NAME_LABEL = 'Name'

export const EMAIL = 'email'
export const EMAIL_LABEL = 'Email'

export const SUBJECT = 'subject'
export const SUBJECT_LABEL = 'Subject'

export const MESSAGE = 'message'
export const MESSAGE_LABEL = 'Message'

export default Yup.object({
  [NAME]: Yup.string()
    .label(NAME_LABEL)
    .required(),
  [SUBJECT]: Yup.string()
    .label(SUBJECT_LABEL)
    .required(),
  [EMAIL]: Yup.string()
    .label(EMAIL_LABEL).email('Invalid email')
    .required(),
  [MESSAGE]: Yup.string()
    .label(MESSAGE_LABEL)
    .required()
})
