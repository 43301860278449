import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { useNavigate } from 'react-router'

import AdminPage from './AdminPage'
import InputField from '../InputField'
import Button from '../Button'
import { ADMIN_HOME_ROUTE } from '../../utils/routes'
import adminLoginValidator, {
  PASSWORD,
  PASSWORD_LABEL,
  EMAIL,
  EMAIL_LABEL
} from '../../validators/adminLoginValidator'

const initialValues = {
  [PASSWORD]: '',
  [EMAIL]: ''
}

export default function AdminLogin () {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('adminOnecultur') &&
      localStorage.getItem('adminOneculturToken')) {
      navigate(ADMIN_HOME_ROUTE)
    }
  }, [navigate])

  return (
    <AdminPage>
      <Box sx={{ paddingTop: '100px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: { xs: '35px', md: '48px' }
          }}
        >Log In
        </Box>
        <Box
          sx={{
            paddingTop: '15px',
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Roboto',
            fontWeight: 400
          }}
        >
          Access OneCultur CMS Portal
        </Box>
      </Box>
      <Container
        sx={{
          paddingTop: '40px',
          maxWidth: { xs: 'lg', md: 'sm' }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={adminLoginValidator}
          onSubmit={async (values, { isSubmitting, resetForm }) => {
            setLoading(true)

            try {
              const { data } = await axios.post(
              `${process.env.REACT_APP_PROD_MODE === 'test'
                ? process.env.REACT_APP_API_URL_TEST
                : process.env.REACT_APP_API_URL_LIVE}/admin/login`,
              { ...values }
              )

              localStorage.setItem('adminOnecultur', JSON.stringify(data.decodeDetails))
              localStorage.setItem('adminOneculturToken', data.token)

              navigate(ADMIN_HOME_ROUTE)

              Notify.success(data.message)
            } catch (error) {
              Notify.failure(error.response.data.msg)
            }
            setLoading(false)
          }}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <InputField
                    type='email'
                    name={EMAIL}
                    error={errors[EMAIL]}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={EMAIL_LABEL}
                    variant='outlined'
                  />
                </Box>
                <Box>
                  <InputField
                    type='password'
                    name={PASSWORD}
                    error={errors[PASSWORD]}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={PASSWORD_LABEL}
                    variant='outlined'
                  />
                </Box>
                <Box>
                  <Button
                    disabled={loading}
                    type='submit'
                    width='100%'
                    variant='contained'
                    backgroundColor='primary.main'
                    text={loading ? <CircularProgress /> : 'Login'}
                    borderRadius='0px'
                  />
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Container>
    </AdminPage>
  )
}
