import Button from '@mui/material/Button'

export default function ButtonComponent ({ variant, text, borderRadius, backgroundColor, width, type, color, height, fontFamily, ...props }) {
  return (
    <Button
      type={type}
      variant={variant}
      sx={{ borderRadius, color, backgroundColor, width, height, fontFamily }}
      {...props}
    >{text}
    </Button>
  )
}
