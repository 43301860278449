import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Fira Sans'
    },
    h2: {
      fontFamily: 'Fira Sans'
    },
    h3: {
      fontFamily: 'Fira Sans'
    },
    h4: {
      fontFamily: 'Fira Sans'
    },
    h5: {
      fontFamily: 'Fira Sans'
    },
    h6: {
      fontFamily: 'Fira Sans'
    },
    body1: {
      fontFamily: 'Bodoni Moda'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
            backgroundColor: '#F69220',
            color: '#fff',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            fontWeight: 600
          }),
          ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'primary' && {
            borderColor: '#0C9347',
            color: '#0C9347',
            textTransform: 'none',
            borderRadius: '50px',
            fontFamily: 'Fira Sans',
            fontWeight: 600
          })
        })
      }
    }
  },
  palette: {
    primary: {
      main: '#0C9347'
    },
    secondary: {
      main: '#F69220',
      subMain: '#EA088B'
    }
  }
})
