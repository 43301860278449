import Box from '@mui/material/Box'

import loadingIcon from '../assets/loadingIcon.svg'
import styles from '../components/styles/loading.module.css'

export default function Loading () {
  return (
    <Box
      sx={{
        backgroundColor: '#FBF7EC',
        minHeight: '100vh'
      }}
    >
      <Box sx={{
        // width: '100%',
        // height: '100%',
        position: 'absolute',
        left: { xs: '35%', md: '50%' },
        top: '50%'
      }}
      >
        <img alt='' className={styles.rotate} src={loadingIcon} width='10%' height='100%' />
      </Box>
    </Box>
  )
}
